const MARKERS = [
  {
    name:'Kweek buitengaats',
    icon:'koraal',
    left:'73%',
    top: '25%',
    image: "/processed/JPEG/3hangcultuurFoto Ad Aleman.jpg",
    otherImages: ["/processed/JPEG/2hangcultuur 2Foto Ad Aleman.jpg","/processed/JPEG/3hangcultuurFoto Ad Aleman.jpg"],
    modal_type: 'TextModal',
    video_thumbnail:"video/thumbnails/buitengaats.JPG",
    video: "mosselbank.mp4",
    text: `<p>Kweek van mosselen en oesters, krabben, kreeften en wieren op zee bieden een alternatief voor inkomsten uit visserij.</p><p>Kweek kan bovendien zaden en larven geven voor nieuwe, wilde vestiging. Meer broed, meer kans. In kweekgebieden is ook minder of geen bodemverstoring door visserij. Dat biedt kansen voor herstel van allerlei wilde soorten.</p>`
  },
  {
    name:'Bodem om op te staan',
    icon:'koraal2',
    left:'77%',
    top: '43%',
    image: "/processed/JPEG/9zeeanemoon Foto Ad Aleman.jpg",
    otherImages: ["/processed/JPEG/4OesterwiegFoto Ernst Schrijver.jpg","/processed/JPEG/5Leven op oestertassen Foto Ernst Schrijver.jpg","/processed/JPEG/Copyright DMP - Holtedieren - Dodemansduim - Alcyonium digitatum - 001.jpg","/processed/JPEG/10wierenwoud Foto Ad Aleman.jpg","/processed/JPEG/8variatie op een rif Foto Ad Aleman.jpg","/processed/JPEG/9zeeanemoon Foto Ad Aleman.jpg"],
    modal_type: 'TextModal',
    text: `<p>In de zanderige Noordzee is gebrek aan stevig materiaal om op te groeien. Op wrakken of kunstmatige structuren kunnen jonge schelpdieren en wieren zich hechten en groeien.</p><p>ARK deed succesvolle proeven met de ‘oesterwieg’: korven op staken, gevuld met lege oesterschelpen waarop al kleine babyoestertjes groeien. Ze zijn een startpunt voor een nieuw rif. </p>`
  },
  {
    name:'Basis voor een rijke gemeenschap',
    icon:'kreeft',
    left:'58%',
    top: '64%',
    image: "/processed/JPEG/11platte oester en annemoon-FloDr.jpg",
    otherImages: ["/processed/JPEG/11platte oester en annemoon-FloDr.jpg","/processed/JPEG/12grote kreeft  foto ad aleman.jpg","/processed/JPEG/13krab op een rif Foto Ad Aleman.jpg","/processed/JPEG/Copyright DMP - Vissen - Haai ei - Selachimorpha - 001.jpg","/processed/JPEG/School-Reindert_Nijland.jpg"],
    modal_type: 'TextModal',
    video_thumbnail:"video/thumbnails/Voordelta Oesterriffen.jpg",
    video: "voedselexplosie.mp4",
    text: `<p>De bodem van de Noordzee bestond ooit voor twintig-dertig procent uit riffen en niet uit zand zoals nu.</p><p>Op riffen van mosselen, oesters of kokerwormen kunnen allerlei andere dieren en wieren leven. Er is veel te eten. In de luwte kunnen vissen schuilen en paaien. Jonge vis groeit er veilig op. De visstand verbetert.</p>`
  },
  {
    name:'Sterke kust, helder water',
    icon:'shrimp',
    left:'48%',
    top: '82%',
    image: "/processed/JPEG/16Kwal in helder water-Reindert_Nijland.jpg",
    otherImages: ["/processed/JPEG/Fouragerende meeuwen op zee (ES).jpg","/processed/JPEG/15zeeanemoon en mosselen Foto Ad Aleman.jpg","/processed/JPEG/16Kwal in helder water-Reindert_Nijland.jpg","/processed/JPEG/14filteren foto ad aleman.jpg","/processed/JPEG/janvangent duikt Foto Ad Aleman.jpg"],
    modal_type: 'TextModal',
    video_thumbnail:"video/thumbnails/helderwater.JPG",
    video: "oesters.mp4",
    text: `<p>Mosselen en oesters filteren water om er eten uit te halen. Slib en zand spugen ze uit en dat zet zich vast. Zo ontstaan schelpdierriffen die de kustverdediging kunnen versterken.</p><p>Het filterwerk van schelpdieren maakt het water helder en algen en wieren kunnen beter groeien: meer zonlicht wordt omgezet in voedsel. Vogels die jagen op zicht profiteren van helder water.</p>`
  },
  {
    name:'Minder plagen en explosies',
    icon:'rog',
    left:'20%',
    top: '54%',
    image: "/processed/JPEG/18vleet Foto  Peter Verhoog.jpg",
    otherImages: ["/processed/JPEG/17zeester wrikt mossel open Foto Ad Aleman.jpg","/processed/JPEG/18vleet Foto  Peter Verhoog.jpg"],
    modal_type: 'TextModal',
    video_thumbnail:"video/thumbnails/zeeanemoon.jpg",
    video: "grindbodem.mp4",
    text: `<p>Roggen eten zeesterren. Zeesterren eten schelpdieren. Met genoeg roggen kunnen schelpdierriffen goed aangroeien en blijven bestaan. In een gebalanceerd ecosysteem zullen plagen en explosies van één soort minder voorkomen.</p><p>De vleet is de grootste rog in de Noordzee, zo groot als een dekbed. De vis in de Noordzee zo goed als uitgestorven. De droom is om de zee weer gezond genoeg te maken om de vleet terug te kunnen brengen.</p>`
  },
  {
    name:'Stabiel met roofdieren',
    icon:'zeehond',
    left:'14%',
    top: '28%',
    image: "/processed/JPEG/20Grijze zeehond Foto Ernst Schrijver.jpg",
    otherImages: ["/processed/JPEG/19Bruinvis Foto Ernst Schrijver.jpg","/processed/JPEG/20Grijze zeehond Foto Ernst Schrijver.jpg","/processed/JPEG/21 haaienPeterVerhoog.jpg","/processed/JPEG/19 Bruinvis (ES).jpg","/processed/JPEG/Zeehonden achter branding (ES).jpg"],
    modal_type: 'TextModal',
    video_thumbnail:"video/thumbnails/roofdier.JPG",
    video: "kelpwoud.mp4",
    text: `<p>Grote roofdieren als haaien, bruinvissen en zeehonden vangen vooral zwakkere vissen. Zo blijven scholen vis gezond.</p><p>Systemen met grote roofdieren die kleinere roofdieren eten zijn vaak stabieler en hebben een rijkere basis dan ecosystemen waarin die kleinere roofdieren ongehinderd hun gang kunnen gaan.</p>`
  },
  {
    name:'Stenen voor riffen',
    icon:'krab2',
    left:'23%',
    top: '21%',
    image: "/processed/JPEG/13krab op een rif Foto Ad Aleman.jpg",
    otherImages: ["/processed/JPEG/13krab op een rif Foto Ad Aleman.jpg","/processed/JPEG/hermietkreeft foto ad aleman.jpg"],
    modal_type: 'TextModal',
    video_thumbnail:"video/thumbnails/stenen voor riffen.JPG",
    video: "stenenbodem.mp4",
    text: `<p>Stenen rond windmolens kunnen dienen als bodem voor rifgroei. Gezocht wordt naar de ideale soort, maat, mix én plaatsing van stenen om riffen te laten groeien.</p><p>De hoop is dat de miniriffen bij windmolens ook rifvorming buiten windmolenparken zal helpen: als er meer schelpdieren zijn, komt meer broed in het water, en nemen de kansen op voortplanting en nieuwe vestiging toe.</p>`
  },
  {
    name:'Tijd om te groeien',
    icon:'zeebaars',
    left:'50%',
    top: '40%',
    image: "/processed/JPEG/23jonge baars Foto Ad Aleman.jpg",
    otherImages: ["/processed/JPEG/23jonge baars Foto Ad Aleman.jpg","/processed/JPEG/24volwassen baars Foto Ad Aleman.jpg","/processed/JPEG/Copyright DMP - Weekdieren - Noordkromp - Arctica islandica - 001.jpg"],
    modal_type: 'TextModal',
    text: `<p>Zeenatuur is vruchtbaar en veerkrachtig. Zonlicht wordt in zee direct omgezet in algen en bacteriën. Zoöplankton voedt zich daarmee. En zoöplankton is weer eten voor vissen. Een groot deel van visseneitjes en larven van weekdieren worden ook opgegeten.</p><p>Om groot te groeien zijn schuilplaatsen nodig en tijd. Zeebaars plant zich na vijf tot acht jaar voort, maar kan wel dertig jaar oud worden. Ze groeien in die tijd steeds groter. De echt grote, oude vrouwtjes leggen veel meer eitjes dan jonge vissen en zijn daarom erg belangrijk voor de soort.</p><p>De noordkromp heeft nog veel meer tijd nodig, dit schelpdier kan wel vierhonderd jaar worden.</p>`
  },
  {
    name:'De levende Noordzee',
    colofon:true,
    icon:'information',
    left:'90%',
    top: '85%',
    image: "/processed/JPEG/strandles foto ad de groot.jpg",
    otherImages: ["/processed/JPEG/strandles foto ad de groot.jpg","/processed/JPEG/1Visserij Foto Ad Aleman.jpg","/processed/JPEG/logoark.jpg"],
    modal_type: 'text',
    text: `<p>Ah de zee, de zee. Soms groen, soms grijs, soms grauw… de ene dag stil, de andere dag ruig. Altijd drukbevaren. Onder de zeespiegel is een andere wereld – die van het geheimzinnige zeeleven. ARK zet zich in om de wilde natuur onder water te versterken en uit te breiden. Om riffen en roofdieren terug te brengen in de zee.</p><br><p>Aan deze ‘Duik mee onder water’ werkten mee:</p><ul><li><a target="_blank" href="https://www.ark.eu/de-mensen-achter-ark/jeroen-helmer">tekening Jeroen Helmer</a>/ARK Natuurontwikkeling</li><li>tekst Iris Roggema/ARK Natuurontwikkeling</li><li><a target="_blank" href="https://www.dutchmaritimeproductions.com">videos Dutch Maritime Productions</a></li><li>foto’s Ad Aleman, Ad de Groot, Udo van Dongen, Cor Kuyvenhoven, Floor Driesen, Reindert Nijland, Ernst Schrijver, Peter Verhoog, Onderwaterbeelden.nl, DMP</li><li><a target="_blank" href="https://www.bramlinssen.nl">realisatie Bram Linssen</a></li><li><a target="_blank" href="https://www.basreijnen.n">ontwerp Bas Reijnen </a></li></ul><p>Meer informatie ark.eu/noordzee</p>`
  }
]

// const LABELS = [
//   {
//     left:'12%',
//     top: '14%',
//     title: 'visdief'
//   },
//   {
//     left:'14%',
//     top: '23%',
//     title: 'gewone zeehond'
//   },
//   {
//     left:'16%',
//     top: '32%',
//     title: 'bruinvis'
//   },
//   {
//     left:'26%',
//     top: '34%',
//     title: 'doornhaai'
//   },
//   {
//     left:'37%',
//     top: '38%',
//     title: 'vleet'
//   },
//   {
//     left:'39%',
//     top: '20%',
//     title: 'zeekoet'
//   },
//   {
//     left:'50%',
//     top: '10%',
//     title: 'jan van gent'
//   },
//   {
//     left:'56%',
//     top: '24%',
//     title: 'noordse stormvogel'
//   },
//   {
//     left:'53%',
//     top: '28%',
//     title: 'haring'
//   },
//   {
//     left:'53%',
//     top: '28%',
//     title: 'haring'
//   },
//   {
//     left:'86%',
//     top: '7%',
//     title: 'grote stern'
//   },
//   {
//     left:'85%',
//     top: '15%',
//     title: 'kleine mantelmeeuw'
//   },
//   {
//     left:'81%',
//     top: '32%',
//     title: 'oorkwal'
//   },
//   {
//     left:'86%',
//     top: '34%',
//     title: 'kompaskwal'
//   },
//   {
//     left:'91%',
//     top: '31%',
//     title: 'zeepaddenstoel'
//   },
//   {
//     left:'83%',
//     top: '41%',
//     title: 'kunstrif'
//   },
//   {
//     left:'72%',
//     top: '45%',
//     title: 'oester- en<br> mosselzaad'
//   },
//   {
//     left:'75%',
//     top: '50%',
//     title: 'oesterwieg: oude schelpen<br>voor nieuwe oesters'
//   },
//   {
//     left:'87%',
//     top: '56%',
//     title: 'makreel'
//   },
//   {
//     left:'88%',
//     top: '59%',
//     title: 'knotswier'
//   },
//   {
//     left:'89%',
//     top: '66%',
//     title: 'pijlinktvis<br>met eitjes'
//   }
// ]

export {MARKERS}